.dashboard__button {
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: var(--color-white);
  background-color: #0004F0;
  padding: 12px 30px;
  border-radius: 5px;
}

.dashboard__button:disabled {
  background-color: #9A9A9A;
}

.button__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}