.content__wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.content__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.content__section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section__top {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section__title {
  color: var(--color-black);
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.section__content {
  width: 615px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background-color: var(--color-white);
  border-radius: 10px;
}

.section__content:last-child {
  margin-bottom: 50px;
}