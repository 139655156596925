.header__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  padding: 10px 0px;
}

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.wrapper_blocked {
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #ffffff; */
  background: #999999;
}

.header__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.header__left_mob {
  display: flex;
  gap: 20px;
}

.header__section {
  display: flex;
  align-items: center;
  gap: 60px;
}

.header__section_admin {
  gap: 10px;
}

.icon__custom {
  width: 20px;
  height: 20px;
}

.icon__custom_offset {
  transform: translateY(2px);
}

.magnifer__visible {
  display: none;
}

.icon__custom path {
  stroke: var(--color-black);
}

.link__text {
  font-size: 16px;
  line-height: 22px;
}

.header__element {
  display: flex;
  align-items: center;
  gap: 6px;
}

@media (width >=768px) {
  .header__left_mob {
    display: none;
  }
}