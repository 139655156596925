.page__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.page__shadow {
  width: 100%;
  height: 100%;
  background-color: var(--primary-bg);
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.page__container {
  width: 100%; 
  max-width: 1128px;
  height: 100%;
  padding-top: 100px;
  z-index: 2;
  color: var(--color-white);
}

.page__title {
  font-weight: 800;
  font-size: 64px;
  line-height: 87px;
  margin-top: 15px;
}

.page__content {
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.page__subtitle {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
}

.ordering__data {
  width: 100%;
  display: flex;
  gap: 24px;
}

.data__grid {
  flex-basis: 66%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 190px 288px 50px;
  column-gap: 24px;
  row-gap: 40px;
}

.grid__item:first-child {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.grid__item:nth-child(3),
.grid__item:last-child {
  grid-column-start: span 2;
}

.item__input {
  width: 100%;
  height: 50px;
  background: var(--color-white);
  border: 1px solid #B9B9B9;
  border-radius: 5px;
  padding: 10px;
}

.input__error {
  border: 3px solid #CC2929;
}

.item__input::placeholder {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #7A7A7A;
}

.item__textarea {
  height: 100%;
}

.item__delivery {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 30px 25px;
  gap: 30px;
  background: var(--color-white);
  border-radius: 5px;
}

.delivery__options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.options__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: var(--color-black);
}

.options__checkboxes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;
}

.checkbox__input {
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
}

.checkbox__label {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-black);
  position: relative;
  display: inline-block;
  left: 40px;
  cursor: pointer;
}

.checkbox__indicator {
  border: 3px solid var(--color-black);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -1px;
  left: -40px;
}

.checkbox__indicator::after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox__input:checked + .checkbox__indicator {
  border-color: var(--color-primary-btn);
}

.checkbox__input:checked + .checkbox__indicator::after{
  display: block;
  border-radius: 50%;
  border: solid var(--color-primary-btn);
  background: var(--color-primary-btn);
  width: 8px;
  height: 8px;
  top: 3.5px;
  left: 3.5px;
}

.options__address {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  position: relative;
}

.address__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: var(--color-black);
}

.ordering__total {
  width: 360px;
  height: 391px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  gap: 20px;
  background: var(--color-white);
  border-radius: 5px;
}

.total__info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.info__content {
  gap: 10px;
}

.content__section{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  color: #7a7a7a;
}

.top__title {
  font-size: 24px;
  line-height: 33px;
  font-weight: 600;
  color: var(--color-black);
}

.section__title,
.section__value {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.top__sum {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: var(--color-black);
}

.top__sum span:last-child {
  margin-left: 8px;
}

.section__value span:last-child {
  margin-left: 6px;
}

.section__divider {
  width: 326px;
  height: 1px;
  background: #D9D9D9;
}

.total__bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.bottom__link {
  max-width: 204px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--color-black);
}

.bottom__link span {
  color: #2A56C6;
}

.submit__input {
  position: absolute;
  right: 0;
  visibility: hidden;
}

@media (width < 769px) {
  .page__container {
    max-width: 704px;
  }

  .page__title {
    font-weight: 700;
    font-size: 48px;
    line-height: 65px;
  }

  .data__grid {
    flex-basis: 100%;
  } 
}

@media (width < 321px) {
  .page__container {
    padding-top: 50px;
    max-width: 304px;
  }

  .page__title {
    font-size: 32px;
    line-height: 44px;
  }

  .page__content{
    margin-top: 30px;
  }
  
  .data__grid {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .grid__item:nth-child(2) {
    min-height: 190px;
  }

  .grid__item:nth-child(3) {
    margin-top: 40px;
  }
}