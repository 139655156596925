.catalog__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-top: 50px;
  padding-bottom: 70px;
}

.catalog__container {
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.catalog__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.header__left {
  display: flex;
  align-items: center;
  gap: 9px;
  transform: translateX(-15px);
}

.left__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  color: var(--color-white);
}

.catalog__grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

@media (width >=768px) {
  .catalog__wrapper {
    padding-bottom: 80px;
  }

  .catalog__header {
    margin: 0;
    margin-bottom: 50px;
  }

  .header__left {
    transform: translateX(-5px);
  }

  .left__title {
    font-size: 48px;
    line-height: 65px;
  }

  .catalog__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    column-gap: 16px;
  }
}

@media (width >=1440px) {
  .catalog__wrapper {
    padding-top: 100px;
    padding-bottom: 120px;
  }

  .left__title {
    font-size: 64px;
    line-height: 87px;
    font-weight: 800;
  }

  .catalog__grid {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 24px;
    row-gap: 36px;
  }

}

@media (width >=1920px) {
  .catalog__container {
    max-width: 1128px !important;
  }
}