.item__wrapper {
  width: 100%;
  background: var(--color-white);
  border-radius: 5px;
  color: var(--color-black);
  position: relative;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.item__head {
  width: 100%;
  display: flex;
  gap: 10px;
}

.head__image {
  width: 54px;
  height: 54px;
  align-self: flex-start;
}

.head__title {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  flex-grow: 1;
}

.icon__custom {
  cursor: pointer;
  stroke: #7A7A7A;
}

.item__info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.item__sum {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.sum__price {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.sum__weight {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #7A7A7A;
  display: flex;
  gap: 2px;
}

.item__counter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.counter__button {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: #F08200;
  position: relative;
}

.button__icon {
  width: 20px;
  height: 20px;
  stroke: var(--color-white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.counter__value {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  gap: 4px;
}

@media (width >= 768px) {
  .item__wrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .item__head {
    flex-basis: 264px;
  }

  .head__title {
    max-width: 200px;
    align-self: center;
  }

  .icon__custom {
    width: 24px;
  }

  .item__info {
    flex-direction: row-reverse;
    align-items: center;
    gap: 40px;
    flex-basis: 50%;
  }

  .item__counter {
    width: 124px;
    gap: 10px;
  }

  .item__sum {
    flex-shrink: 10;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
}

@media (width >= 1440px) {
  .item__info {
    flex-basis: 360px;
  }
}
