.dashboard__wrapper {
  width: 100vw;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  background-color: #FAFDFF;
}

.modal__blocked {
  pointer-events: none;
}

.shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(138, 138, 138, .4);
  z-index: 30;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard__content {
  width: 100%;
  /* height: 95vh; */
  display: flex;
}

.dashboard__outlet {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 40px 86px;
  overflow-y: auto;
}