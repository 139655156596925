.status__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-black);
  border-radius: 5px;
  padding: 5px 15px;
}

.completed {
  background-color: #FBCC27;
}

.in_progress {
  background-color: #89C6FF;
}

.payed {
  background-color: #9DFB27;
}

.waiting {
  background-color: #E789FF;
}

.status__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  white-space: nowrap;
}