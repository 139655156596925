.item__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.item__image {
  width: 40px;
  height: 40px;
}

.item__content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item__input {
  width: 80px;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--color-white);
  border: 1px solid #e8e8e8ce;
  color: black;
  margin: 0 6px;
}
.select__input{
  width: 100%;
  margin-bottom: 10px;
}
.icon__custom {
  width: 18px;
  height: 18px;
  stroke: var(--color-black);
  fill: transparent;
  cursor: pointer;
}
.add__button{
  background-color: rgb(0, 81, 255);
  color: white;
  padding: 3px;

  height: 30px;
  border-radius: 5px;
}
.del__button{
  background-color: rgb(8, 94, 51);
  color: white;
  padding: 3px;
 
  height: 30px;
  border-radius: 5px;
}

.product_title{
width: 100%;
max-width: 500px;
}
.content__text {
  color: var(--color-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.content__text:first-child {
  width: 360px;
}