.profile__wrapper {
  width: 100%;
  height: 100%;
  min-height: 1000px;
  display: flex;
  justify-content: center;
  position: relative;
}

.repeat__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 30px;
  border: 1px solid var(--color-primary-btn);
  border-radius: 5px;
  color: var(--color-black);
  outline: none;
  background: transparent;
}

.repeat__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  white-space: nowrap;
}

.page__shadow {
  width: 100%;
  height: 100%;
  background-color: var(--primary-bg);
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.profile__container {
  width: 100%;
  max-width: 1128px !important;
  height: 100%;
  padding-top: 200px;
  z-index: 2;
}

.profile__title {
  font-weight: 800;
  font-size: 64px;
  line-height: 87px;
  color: var(--color-white);
  margin-top: 15px;
}

.profile__personal {
  margin-top: 40px;
  width: 100%;
  display: flex;
  gap: 24px;
}

.personal__section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.exit__section {
  width: 264px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: var(--color-white);
  border-radius: 5px;
  padding: 30px;
}

.form__section {
  width: 840px;
  background-color: var(--color-white);
  display: flex;
  gap: 40px;
  border-radius: 5px;
  padding: 30px;
}

.img__exit {
  padding-bottom: 50px;
}

.section__title,
.timeline__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: var(--color-black);
}

.timeline__title {
  color: var(--color-white);
}

.section__input {
  width: 100%;
  height: 50px;
  border: 1px solid #B9B9B9;
  border-radius: 5px;
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-black);
}

.section__input::placeholder {
  color: #7A7A7A;
  ;
}

.button__right {
  align-self: flex-end;
}

.orders__timeline {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.timeline__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 27px;
}

@media (width < 769px) {
  .profile__container {
    padding-top: 50px;
    max-width: 704px;
  }

  .personal__section {
    width: 456px;
  }

  .profile__title {
    font-weight: 700;
    font-size: 48px;
    line-height: 65px;
  }

  .profile__personal {
    margin-top: 50px;
    flex-direction: column;
    gap: 60px;
  }

  .button__right {
    align-self: flex-start;
  }

  .form__section {
    flex-direction: column;
    background-color: transparent;
    padding: 0;
  }

  .section__title {
    color: var(--color-white);
  }
}

@media (width < 321px) {
  .profile__container {
    max-width: 288px;
  }

  .profile__title {
    font-size: 32px;
    line-height: 44px;
    margin-top: 38px;
  }

  .form__section {
    width: 100%;
  }

  .personal__section {
    width: 100%;
  }
}