.step__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 80px;
}

.step__container {
  width: 100%;
  height: 100%;
}

.step__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 52px;
}

.content__item {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 5;
}

.item__spotlight {
  background-image: url('../../assets/img1/item_spotlight.png');
  background-repeat: no-repeat;
  background-position: center;
  width: 320px;
  height: 288px;
  position: absolute;
  top: -75px;
  left: -60px;
}

.item__spotlight:last-child {
  height: 500px;
}

.item__img {
  width: 126px;
  height: 126px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.item__one {
  background-image: url('../../assets/img1/icon_shedule.png');
}

.item__two {
  background-image: url('../../assets/img1/icon_delivery.png');
}

.item__three {
  background-image: url('../../assets/img1/icon_contacts.png');
}

.item__content {
  text-align: center;
}

.item__title {
  color: #FDFDFD;
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
}

.item__subtitle {
  color: var(--color-white);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

@media (width >= 768px) {
  .step__wrapper {
    padding-bottom: 100px;
  }

  .step__content {
    flex-direction: row;
  }

  .item__img {
    width: 170px;
    height: 170px;
  }
}

@media (width >= 1440px) {
  .step__wrapper {
    padding-bottom: 170px;
  }

  .step__container{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .step__content {
    gap: 100px;
  }

  .item__spotlight {
    width: 320px;
  }
}

@media (width >= 1920px) {
  .step__wrapper {
    padding-bottom: 0;
  }

  .step__content {
    transform: translateY(-170px);
  }

  .content__item {
    transform: translateY(30px);
  }
}