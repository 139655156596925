.step_six__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.step_six__container {
  width: 100%;
  padding-top: 172px;
  padding-bottom: 110px;
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.step_six__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.content__header {
  display: flex;
  align-items: center;
  gap: 9px;
}

.icon__custom {
  width: 40px;
  height: 40px;
  fill: var(--color-white);
}

.header__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  color: var(--color-white);
}

.catalog__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
}

.list__item,
.button__block {
  width: 100%;
}

@media (width >=768px) {
  .icon__custom {
    width: 60px;
    height: 60px;
  }

  .header__title {
    font-size: 64px;
    font-weight: 800;
    line-height: 87px;
  }

  .catalog__list {
    gap: 50px;
  }
}

@media (width >=1440px) {
  .step_six__container {
    padding-bottom: 180px;
  }

  .button__block {
    width: 184px;
  }
}

@media (width >=1920px) {
  .step_six__container {
    max-width: 1125px !important;
  }
}