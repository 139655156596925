.step_four__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.step_four__container {
  width: 100%;
  padding-top: 110px;
  padding-bottom: 238px;
  display: flex;
  justify-content: center;
}

.step_four__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.content__title {
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: var(--color-bright-yellow);
}

.content__section {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.section__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--color-white);
}

.item__top {
  display: flex;
  gap: 16px;
  position: relative;
  max-width: 285px;
}

.top__left {
  font-size: 64px;
  font-weight: 800;
  line-height: 87px;
}

.top__right {
  display: flex;
  justify-content: center;
  align-items: center;
}

.right__highlight {
  width: 320px;
  height: 320px;
  position: absolute;
  right: 8px;
  background: url('../../../assets/img1/item_spotlight_2.png');
  background-position: right;
}

.item__bottom {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.bottom__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
}

.bottom__text {
  max-width: 202px;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.icon__custom {
  stroke: var(--color-white);
  fill: transparent;
  align-self: center;
}

.step_way_one {
  width: 332px;
  height: 139px;
  background-image: url('../../../assets/img/steps_way1.png');
  background-repeat: no-repeat;
  position: absolute;
  left: 240px;
  top: 215px;
}

.step_way_two {
  width: 586px;
  height: 159px;
  background-image: url('../../../assets/img/steps_way2.png');
  background-repeat: no-repeat;
  position: absolute;
  top: 540px;
  left: 60px;
}

.step_way_three {
  width: 230px;
  height: 160px;
  background-image: url('../../../assets/img/steps_way3.png');
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  bottom: 355px;
  left: 45px;
}

@media (width >=768px) {
  .step_four__container {
    padding-top: 120px;
    padding-bottom: 160px;
  }

  .step_four__content {
    gap: 95px;
  }

  .content__section {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    row-gap: 160px;
  }

  .content__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
  }

  .item_one {
    grid-column: 1/5;
  }

  .item_two {
    grid-column: 5/9;
  }

  .item_three {
    grid-column: 1/-1;
  }

  .item_four {
    grid-column: 4/9;
    transform: translateY(-80px);
  }

  .section__item:nth-child(even) {
    flex-direction: column-reverse;
  }

  .bottom__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
  }

  .bottom__text {
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
    max-width: 300px;
  }

  .bottom__text:nth-child(3) {
    max-width: 286px;
  }
}

@media (width >=1024px) {
  .step_way_one {
    top: 220px;
    left: 360px;
  }

  .step_way_two {
    top: 520px;
    left: 190px;
  }

  .step_way_three {
    bottom: 350px;
    left: 170px;
  }
}

@media (width >=1440px) {
  .step_four__wrapper {
    transform: translateY(600px);
  }

  .step_four__container {
    padding-top: 0;
    padding-bottom: 600px;
  }

  .step_four__content {
    gap: 72px;
  }

  .content__section {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    row-gap: 138px;
    margin-bottom: 200px;
  }

  .item_one {
    grid-column: 1/6;
  }

  .item_two {
    grid-column: 7/12;
  }

  .item_three {
    grid-column: 2/8;
  }

  .item_four {
    grid-column: 8/13;
    transform: translateY(0);
  }

  .step_way_one {
    width: 375px;
    top: 105px;
    left: 380px;
  }

  .step_way_two {
    top: 350px;
    left: 395px;
  }

  .step_way_three {
    transform: rotate(-35deg);
    bottom: 730px;
    left: 620px;
  }
}

@media (width >=1920px) {
  .step_four__wrapper {
    transform: translateY(0);
  }

  .step_four__container {
    padding-top: 50px;
    padding-bottom: 180px;
  }

  .step_four__content {
    width: 1125px;
  }

  .step_way_one {
    left: 600px;
    top: 150px;
  }

  .step_way_two {
    top: 395px;
    left: 640px;
  }

  .step_way_three {
    bottom: 310px;
    left: 865px;
  }
}

@media (width >=2560px) {
  .step_way_one {
    top: 110px;
    left: 930px;
  }

  .step_way_two {
    top: 350px;
    left: 950px;
  }

  .step_way_three {
    left: 1185px;
  }
}
