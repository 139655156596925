.container {
  background: #ee5b21;
  border: 1.5px solid #f42c4f;
  max-width: 600px;
  position: absolute;
  top: 200px;
  left: 33%;
  height: 200px;
  z-index: 999;
  display: flex;
  padding: 28px;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 15px;
  font-size: 18px;
}

.container__white {
  background: white;
  max-width: 600px;
  position: absolute;
  gap: 40px;
  top: 200px;
  height: 200px;
  z-index: 999;
  display: flex;
  flex-direction: column;
  padding: 28px;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 15px;
  border: 2px solid #ee5b21;
  font-size: 18px;
}
.row {
  display: flex;
  gap: 50px;
}

.cross__icon {
  font-weight: 400;
  margin-left: auto;
  font-size: 24px;
}

@media (max-width: 860px) {
  .container,
  .container__good {
    margin: 32px 32px 0;
    max-width: 280px;
  }

  .container,
  .container__good {
    font-size: 12px;
  }
}
