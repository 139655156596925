.step_five__wrapper {
  width: 100%;
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  position: relative;
}

.step_five__container {
  width: 100%;
  padding-top: 135px;
  padding-bottom: 35px;
}

.step_five__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 36px;
}

.content__header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.header__top {
  color: #FAB400;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.header__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
}

.content__body {
  display: flex;
  flex-direction: column;
  gap: 26px;
  align-items: flex-start;
}

.body__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.content__grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}

.grid__item {
  border-radius: 6.5px;
  overflow: hidden;
  background-color: var(--color-black);
}

.grid__item_1 {
  grid-column: 1/-1;
}

.grid__item_2:first-child,
.grid__item_3:first-child {
  grid-column: 1/7;
}

.grid__item_2:last-child,
.grid__item_3:nth-child(2) {
  grid-column: 7/13;
}

.grid__item_3:last-child {
  grid-column: 1/-1;
}

.grid__item_4:nth-child(odd) {
  grid-column: 1/7;
}

.grid__item_4:nth-child(even) {
  grid-column: 7/13;
}

.grid__item_5:first-child {
  grid-column: 1/5;
}

.grid__item_5:nth-child(2) {
  grid-column: 5/9;
}

.grid__item_5:nth-child(3) {
  grid-column: 9/13;
}

.grid__item_5:nth-child(4),
.grid__item_6:nth-child(5) {
  grid-column: 1/7;
}

.grid__item_5:last-child,
.grid__item_6:last-child {
  grid-column: 7/13;
}

.grid__item_6:first-child {
  grid-column: 1/4;
}

.grid__item_6:nth-child(2) {
  grid-column: 4/7;
}

.grid__item_6:nth-child(3) {
  grid-column: 7/10;
}

.grid__item_6:nth-child(4) {
  grid-column: 10/13;
}

.icon__custom {
  width: 55px;
  height: 53px;
}

.tomato {
  width: 387px;
  height: 243px;
  position: absolute;
  top: -115px;
  right: -120px;
  background-image: url('../../../assets/img1/tomato.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.leaf {
  width: 132px;
  height: 184px;
  position: absolute;
  bottom: -110px;
  left: -30px;
  background-image: url('../../../assets/img1/leaf.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

media-player,
media-outlet {
  height: 100%;
}


@media (width >=768px) {
  .step_five__container {
    padding-top: 146px;
    padding-bottom: 161px;
  }

  .header__top {
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
  }

  .header__title {
    font-size: 48px;
    font-weight: 700;
    line-height: 65px;
  }

  .content__body {
    gap: 40px;
  }

  .body__text {
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
  }

  .leaf {
    left: -18px;
  }

  .tomato {
    width: 554px;
    height: 348px;
    top: -160px;
    right: -150px;
  }

  .content__grid {
    row-gap: 20px;
    column-gap: 16px;
  }

  .content__grid_1 {
    grid-template-rows: 387px;
  }

  .content__grid_2 {
    grid-template-rows: 232px;
  }

  .content__grid_3 {
    grid-template-rows: 232px 387px;
  }

  .content__grid_4 {
    grid-template-rows: 232px 232px;
  }

  .content__grid_5 {
    grid-template-rows: 232px 232px 387px;
  }

  .grid__item_1,
  .grid__item_3:last-child {
    grid-column: 1/-1;
  }

  .grid__item_2:first-child,
  .grid__item_4:nth-child(odd),
  .grid__item_5:nth-child(odd) {
    grid-column: 1/5;
  }

  .grid__item_2:last-child,
  .grid__item_4:nth-child(even),
  .grid__item_5:nth-child(even) {
    grid-column: 5/9;
  }

  .grid__item_3:first-child {
    grid-column: 1/5;
  }

  .grid__item_3:nth-child(2) {
    grid-column: 5/9;
  }

  .grid__item_5:last-child {
    grid-column: 1/-1;
  }
}

@media (width >=1440px) {
  .content__grid {
    grid-template-columns: repeat(12, 1fr);
  }

  .content__grid_1 {
    grid-template-rows: 546px;
  }

  .content__grid_2 {
    grid-template-rows: 326px;
  }

  .content__grid_3,
  .content__grid_4 {
    grid-template-rows: 265px 265px;
    row-gap: 20px;
    column-gap: 24px;
  }

  .content__grid_5,
  .content__grid_6 {
    grid-template-rows: 180px 326px;
    column-gap: 24px;
    row-gap: 42px;
  }

  .grid__item_1 {
    grid-column: 1/-1;
  }

  .grid__item_2:first-child,
  .grid__item_3:first-child {
    grid-column: 1/7;
  }

  .grid__item_2:last-child,
  .grid__item_3:nth-child(2) {
    grid-column: 7/13;
  }

  .grid__item_3:last-child {
    grid-column: 1/-1;
  }

  .grid__item_4:nth-child(odd) {
    grid-column: 1/7;
  }

  .grid__item_4:nth-child(even) {
    grid-column: 7/13;
  }

  .grid__item_5:first-child {
    grid-column: 1/5;
  }

  .grid__item_5:nth-child(2) {
    grid-column: 5/9;
  }

  .grid__item_5:nth-child(3) {
    grid-column: 9/13;
  }

  .grid__item_5:nth-child(4),
  .grid__item_6:nth-child(5) {
    grid-column: 1/7;
  }

  .grid__item_5:last-child,
  .grid__item_6:last-child {
    grid-column: 7/13;
  }

  .grid__item_6:first-child {
    grid-column: 1/4;
  }

  .grid__item_6:nth-child(2) {
    grid-column: 4/7;
  }

  .grid__item_6:nth-child(3) {
    grid-column: 7/10;
  }

  .grid__item_6:nth-child(4) {
    grid-column: 10/13;
  }

  .tomato {
    width: 1024px;
    height: 644px;
    top: -305px;
    right: -275px;
  }

  .leaf {
    width: 309px;
    height: 431px;
    left: -40px;
    bottom: -260px;
  }
}

@media (width >=1920px) {
  .step_five__container {
    max-width: 1125px !important;
  }
}

/*
   grid-template-columns: repeat(8, 1fr);
  grid-template-rows: 232px 232px 387px;
  column-gap: 16px;
  row-gap: 20px;
*/