.breadscrumbs__item {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-white);
  display: flex;
  gap: 5px;
}

.breadscrumbs__item:hover {
  text-decoration: underline;
}

.item__current {
  display: inline-block;
}

.item__current:hover {
  text-decoration: none;
}

.item__icon {
  width: 24px;
  height: 24px;
  transform: translateY(-3px);
}

.item__icon path {
  stroke: var(--color-white);
}

@media (width >=768px) {
  .breadscrumbs__item {
    font-size: 20px;
    line-height: 27px;
  }

  .item__icon {
    transform: translateY(3px);
  }
}