.popup__wrapper {
  width: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 10px;
  background: var(--color-white);
  color: var(--color-black);
  position: absolute;
  top: 0;
  left: 0;
  border: 1px solid #a7a7a7;
  border-radius: 5px;
  z-index: 5;
}

.popup__title {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
}

.popup__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.popup__buttons {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.popup__button {
  width: 45%;
  background-color: transparent;
  padding: 12px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #F08200;
}

.button__confirm {
  background-color: var(--color-primary-btn);
  color: var(--color-white);
}

@media (width >=768px) {
  .popup__wrapper {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
  }
}

@media (width >=1440px) {
  .popup__wrapper {
    top: 54px;
    left: 100%;
    transform: translateX(-93%);
  }
}