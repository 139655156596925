.goods__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.goods__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.goods__top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.top__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.list {
  height: 100%;
  overflow: hidden;
}