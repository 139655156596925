.layout__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-bg);
  background-image: url('../../assets/img1/bg_mob.png');
  background-position: top 80px center;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.layout__container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.content__blocked {
  width: 100%;
  height: 85vh;
  background-color: #999999;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

@media (width >= 425px) {
  .layout__wrapper {
    background-image: url('../../assets/img1/bg_tablet.png');
  }
}

@media (width >= 1024px) {
  .layout__wrapper {
    background-image: url('../../assets/img1/bg_desk.png');
  }
}
