.select__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.select__container {
  width: 100%;
  height: 100%;
}

.select__input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #B9B9B9;
  padding: 10px;
  color: var(--color-black);
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.select__input:disabled {
  background-color: var(--color-white);
}

.select__input::placeholder {
  color: #B9B9B9;
}

.select__caret {
  width: 24px;
  height: 24px;
  background: url('../../../assets/svg/d_select_caret.svg');
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.options__list {
  width: calc(100% - 2px);
  background-color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  position: absolute;
  top: 42px;
  left: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  z-index: 10;
  border: 1px solid #B9B9B9;
}

.list__option {
  color: var(--color-black);
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  border-radius: 5px;
  padding-left: 10px;
  cursor: pointer;
}

.list__option:hover {
  background-color: #B9B9B9;
}