.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}

.footer__blocked {
  display: none;
}

.footer__top {
  width: 100%;
  height: 200px;
  background-image: url('../../assets/img1/footer_smoke_mob.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  transform: translateY(1px);

}

.footer__bottom {
  width: 100%;
  height: 308px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7f7f7;
}

.bottom__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.bottom__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.top__main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}

.main__top {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
}

.main__bottom {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
}

.top__info {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.top__socials {
  display: flex;
  gap: 10px;
}

.bottom__logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (width >=768px) {
  .footer__top {
    background-image: url('../../assets/img1/footer_smoke_tablet.png');
  }

  .footer__bottom {
    padding: 70px 0px;
  }

  .bottom__container {
    flex-direction: row-reverse;
  }

  .top__info {
    margin-top: 0;
    align-items: flex-end;
  }

  .bottom__logo {
    height: 100%;
    align-items: flex-end;
    transform: translateY(30px);
  }

  .bottom__top {
    align-items: flex-end;
  }

  .top__main {
    align-items: flex-end;
  }
}

@media (width >=1024px) {
  .footer__top {
    height: 411px;
    background-image: url('../../assets/img1/footer_smoke_desk.png');
    background-position: bottom;
  }
}

@media (width >=1920px) {
  .footer__top {
    height: 548px;

  }
}