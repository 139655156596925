.list__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  background-color: var(--color-white);
}

/* header */

.list__header {
  width: 100%;
  height: 29px;
  color: #767676;
  display: flex;
  padding: 0px 10px;
}

.header__variation {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 10px;
}

.variation__aside {
  flex-shrink: 1;
}

.variation__main {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.main__left {
  flex-basis: 51%;
  display: flex;
  gap: 80px;
}

.main__right {
  flex-basis: 35%;
  display: flex;
  justify-content: space-between;
}

.right__aside {
  display: flex;
  gap: 60px;
}

.main__left>.header__item:last-child {
  justify-content: center;
}

/* items */

.header__item {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
}

.item__visible:last-child {
  justify-content: flex-end;
}

.hidden__last:last-child {
  visibility: hidden;
}

.orders__container {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.orders__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
/* тут */
::-webkit-scrollbar {
  width: 0;
  appearance: none;
}