.card__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  justify-content: space-between;
  background: var(--color-white);
  border-radius: 5px;
  color: var(--color-black);
  gap: 10px;
}

.card__top {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card__title {
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  max-height: 66px;
}

.card__category {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  cursor: default;
}

.card__image {
  width: 100%;
  height: 248px;
}

.card__footer {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer__left {
  width: 50%;
  display: flex;
  align-items: center;
}

.left__link {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.left__link:hover {
  text-decoration: underline;
}

.footer__right {
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
}

.icon {
  width: 24px;
  height: 24px;
  stroke: var(--color-white);
}

@media (width >=768px) {
  .card__wrapper {
    width: 344px;
    height: 493px;
  }

  .card__image {
    height: 280px;
  }

  .card__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
    max-height: 66px;
  }

  .card__category,
  .left__link {
    font-size: 20px;
    line-height: 27px;
  }

  .footer__left {
    padding: 10px;
  }

  .footer__right {
    width: 46%;
  }
}

@media (width >=1440px) {
  .card__wrapper {
    width: 360px;
    min-height: 493px;
  }

  .card__image {
    height: 280px;
  }

  .footer__right {
    width: 43.75%;
  }
}