.cart__wrapper {
  width: 100%;
  height: 100%;
  min-height: 802px;
  display: flex;
  justify-content: center;
  position: relative;
}

.cart__container {
  width: 100%;
  padding-top: 50px;
  z-index: 2;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
}

.cart__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
}

.cart__carousel {
  margin-top: 40px;
}

@media (width >=768px) {
  .cart__container {
    gap: 0;
  }

  .cart__title {
    font-size: 48px;
    line-height: 65px;
  }

  .cart__carousel {
    margin-top: 60px;
  }
}

@media (width >=1440px) {
  .cart__container {
    padding-top: 100px;
    padding-bottom: 66px;
  }

  .cart__title {
    font-size: 64px;
    font-weight: 800;
    line-height: 87px;
  }

  .cart__content {
    gap: 60px;
  }
}

@media (width >=1920px) {
  .cart__container {
    max-width: 1128px !important;
  }
}