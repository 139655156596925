.detail__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.detail__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.detail__top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add__button{
border: rgb(79, 104, 91)  1px solid ;
width: 140px;
  padding: 5px;
  margin: 15px 0;
  border-radius: 3px;
  cursor: pointer;
  background: rgb(162, 207, 185);
}
.column{
  display: flex;
  flex-direction: column;
  margin: 10px 0;
}
.add__button:hover{
  background: rgb(60, 185, 123);
}
.sum{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.add__button:disabled{
  background: rgb(215, 221, 218);
  cursor: not-allowed;
}
.detail__title {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  color: var(--color-black);
}

.details__widget {
  width: 100%;
  flex-direction: column;
  display: flex;
  color: var(--color-black);
  gap: 20px;
  margin-bottom: 120px;
}

.details__widget:last-child {
  /* height: 100%; */
  /* overflow: hidden; */
}

.order__content {
  height: 100%;
  /* overflow-y: auto; */
  background-color: var(--color-white);
  padding: 20px;
  border-radius: 10px;
}

.content__list {
  width: 100%;
  display: flex;
  flex-direction: column; 
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.list__item {
  min-height: 100%;
  width: 100%;
}

.first {
  background-color: aqua;
}

.second {
  background-color: purple;
}

.widget__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}