.detail__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
}

.detail__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 45px;
}

.detail__section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section__top {
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section__title {
  color: var(--color-black);
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.section__content {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  background-color: var(--color-white);
  border-radius: 5px;
}

.row {
  display: flex;
  gap: 30px;
}

.section__content:last-child {
  margin-bottom: 50px;
}

.section__left {
  width: 648px;
}

.section__right {
  width: 456px;
  position: absolute;
}

.simple__input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #B9B9B9;
  padding: 10px;
  color: var(--color-black);
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.simple__input::placeholder {
  color: #B9B9B9;
}

.simple__textarea {
  height: 177px;
  overflow-y: auto;
  resize: none;
}

.seo__textarea {
  height: 108px;
  resize: none;
}

.select__block {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon__custom {
  stroke: var(--color-black);
}

.switch__section {
  display: flex;
  gap: 12px;
}

.switch__text {
  color: var(--color-black);
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  cursor: pointer;
}

.section__relative {
  width: 100%;
  position: relative;
}

.photo__section {
  top: -325px;
  right: 0;
}

.photo__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  align-items: center;
}

.photo__images {
  display: flex;
  gap: 4px;
  overflow: auto;
}

.images__item {
  width: 83px;
  height: 67px;
  border-radius: 5px;
  overflow: hidden;
}

.images__item img {
  width: 100%;
  height: 100%;
}

.btn__container {
  width: 200px;
}

.seo__section {
  top: 0;
  right: 0;
}

.recomend__section {
  height: 100%;
  top: 380px;
  right: 0;
}

.seo__description {
  color: #B9B9B9;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.recomend__top {
  display: flex;
  flex-direction: column;
}

.errorIn {
  border: solid red 2px;
  background-color: rgba(220, 20, 60, 0.021);
}

.text__error {
  color: #FF9666;
  margin-bottom: 20px;
}

.replay {
  cursor: pointer;
  font-size: 18px;
}

.wrapper {
  padding: 0 20px;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 10px;
  position: relative;
}

.photo__wrapper {
  flex-direction: column;
  align-items: center;
  margin: auto;
  gap: 30px;
  justify-content: center;
  display: flex;
}

.other__button {
  background-color: #0004F0;
  color: var(--color-white);
  width: 100%;
  padding: 12px 30px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  border-radius: 5px;
}

@media (width >=1920px) {
  .section__relative {
    width: 75%;
  }
}