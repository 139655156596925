.step_three__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.step_three__container {
  width: 100%;
  padding-top: 50px;
  display: flex;
}

.step_three__content {
  display: flex;
  flex-direction: column;
}

.content__image {
  width: 288px;
  height: 169px;
  margin-bottom: 50px;
  position: relative;
  z-index: 5;
}

.content__section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.section__title {
  color: #FAB400;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px;
}

.section__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
}

.list__item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  color: var(--color-white);
  max-width: 210px;
}

.item__top {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
}

.item__bottom {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

@media (width >=768px) {
  .step_three__container {
    padding-top: 30px;
    padding-bottom: 120px;
  }

  .step_three__content {
    transform: translateY(90px);
  }

  .content__image {
    width: 100%;
    height: 412px;
    margin-bottom: 60px;
  }

  .section__title {
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
  }

  .list__item {
    max-width: 100%;
  }

  .item__top {
    font-size: 48px;
    font-weight: 700;
    line-height: 65px;
  }

  .item__bottom {
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
  }
}

@media (width >=1440px) {
  .step_three__wrapper {
    transform: translateY(-200px);
  }

  .step_three__container {
    padding-top: 0;
  }

  .step_three__content {
    flex-direction: row;
    position: absolute;
    left: -88px;
  }

  .content__image {
    width: 724px;
    height: 424px;
  }

  .content__section {
    transform: translateY(-80px);
  }
}

@media (width >=1920px) {
  .step_three__content {
    position: relative;
    left: 0;
  }
}