.order__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background: var(--color-white);
  border-radius: 10px;
  position: relative;
}

.order__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px
}

.container__header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.top__block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.header__top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.top__element {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #7A7A7A;
}

.header__middle {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  color: var(--color-black);
}

.header__bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.bottom__element {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.icon__custom {
  width: 24px;
  height: 24px;
  stroke: #7A7A7A;
  cursor: pointer;
}

.bottom__element span {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  color: #7A7A7A;
}

.order__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
}

.list__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.item__left {
  width: 50%;
}

.item__text {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: var(--color-black);
  width: 100%;
}

.item__digits {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
}

.digits__top {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: var(--color-black);
}

.digits__bottom {
  color: #7A7A7A;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: right;
}

.repeat__button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  border: 1px solid var(--color-primary-btn);
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-black);
  outline: none;
  background: transparent;
}
.repeat__button:disabled{
  background-color: #7A7A7A;
  pointer-events: none;
}

.tablet__position {
  position: absolute;
  top: 40px;
  right: 40px;
}

.repeat__button:hover {
  background: var(--color-primary-btn);
  color: var(--color-white);
  transition: all 0.2s linear;
}

@media (width >=768px) {
  .order__wrapper {
    padding: 40px;
  }

  .order__container {
    gap: 30px;
  }

  .item__left {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .item__digits {
    justify-content: center;
  }

  .digits__bottom {
    text-align: left;
  }

  .repeat__button {
    width: 145px;
  }
}

@media (width >=1440px) {
  .container__header {
    gap: 20px;
  }

  .top__block {
    flex-direction: row-reverse;
    width: 414px;
    justify-content: space-between;
    align-items: center;
  }

  .header__top {
    max-width: 200px;
    justify-content: flex-start;
    gap: 20px;
  }

  .top__element {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }

  .header__middle {
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
  }

  .order__list {
    gap: 15px;
  }

  .list__item {
    justify-content: flex-start;
    gap: 50px;
  }

  .item__left {
    width: 380px;
  }

  .item__digits {
    flex-direction: row-reverse;
    gap: 50px;
  }

  .digits__top,
  .digits__bottom {
    color: var(--color-black);
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
  }
}