.viewer__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.viewer__header {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.viewer__form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 60px;
  justify-content: flex-start;
}

.form__left {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.form__top {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form__inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form__input {
  width: 100%;
  height: 50px;
  background: var(--color-white);
  border: 1px solid #B9B9B9;
  border-radius: 5px;
  padding: 10px;
}

.input__error {
  border: 3px solid #ff4747;
}

.form__textaria {
  width: 100%;
  height: 190px;
  display: block;
  border-radius: 5px;
  padding: 10px;
}

.form__input::placeholder,
.form__textaria::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #7A7A7A;
}

.delivery__details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  background-color: var(--color-white);
  border-radius: 5px;
}

.details__top {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.details__header {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  color: var(--color-black);
}

.top__checkboxes {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.checkbox__input {
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
}

.checkbox__label {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-black);
  position: relative;
  display: inline-block;
  left: 40px;
  cursor: pointer;
}

.checkbox__indicator {
  border: 3px solid var(--color-black);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
  top: -1px;
  left: -40px;
}

.checkbox__indicator::after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox__input:checked+.checkbox__indicator {
  border-color: var(--color-primary-btn);
}

.checkbox__input:checked+.checkbox__indicator::after {
  display: block;
  border-radius: 50%;
  border: solid var(--color-primary-btn);
  background: var(--color-primary-btn);
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.details__bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart__total {
  width: 100%;
  background-color: var(--color-white);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  color: var(--color-black);
  padding: 20px;
  gap: 20px;
}

.total__top {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.total__item {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.item__element {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #7A7A7A;
}

.item__total {
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  color: var(--color-black);
}

.total__divider {
  width: 100%;
  height: 1px;
  background: #D9D9D9;
}

.bottom__link {
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--color-black);
}

.bottom__link span {
  color: #2A56C6;
}

@media (width >=768px) {

  .viewer__form,
  .form__left {
    gap: 30px;
  }

  .form__top {
    flex-direction: row;
    gap: 16px;
  }

  .viewer__header {
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
  }
}

@media (width >=1440px) {
  .viewer__wrapper {
    padding-bottom: 90px;
  }

  .viewer__form {
    flex-direction: row;
    gap: 24px;
  }

  .form__left {
    width: 200%;
    gap: 40px;
  }

  .cart__total {
    height: 332px;
    padding: 30px;
  }
}