.page__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.page__container {
  width: 100%; 
  padding-top: 50px;
  z-index: 2;
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.page__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
}

@media (width >= 768px) {
  .page__title {
    font-size: 48px;
    font-weight: 700;
    line-height: 65px;
  }
}

@media (width >= 1920px) {
  .page__container {
    max-width: 1128px !important;
  }
}

