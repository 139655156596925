.catalog_btn__wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.catalog_btn__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.icon__custom path{
  fill: #333333;
  stroke: none;
}

.catalog_btn__text_w {
  color: var(--color-white);
}

.icon__custom_w path {
  fill: var(--color-white);
}