.list__item {
  width: 140px;
  height: 80px;
  position: relative;
}

.list__item img {
  width: 100%;
  height: 100%;
}

.delete__icon {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  fill: #FAB400;
  stroke: var(--color-white);
  cursor: pointer;
  z-index: 5;
}

.list__item:hover .delete__icon {
  display: block;
  transition: all 2s ease;
}