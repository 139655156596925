.popup__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 70px;
  left: 0;
}
.popup__wrapper__prof{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 270px;
  left: 0;
  z-index: 10;
  
}
.popup__container {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  border-radius: 10px;
  gap: 70px;
}
.popup__container__prof {

  max-width: 400px;
  padding: 20px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  border-radius: 10px;
  gap: 70px;
  border: 2px solid orange;
}

.popup__text {
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
}

.text__string {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

@media (width >= 768px) {
  .popup__wrapper {
    width: 456px;
    top: 68px;
    left: 50%;
    transform: translateX(-50%);
  }

  .popup__container {
    width: 456px;
  }

  .text__string {
    font-size: 24px;
    line-height: 33px;
  }
}

@media (width >= 1440px) {
  .popup__wrapper {
    top: 100px;
    left: 27%;
  }
}