.container {
  margin-left: 20px;
  height: 285px !important;
  width: 245px !important;
}

.item__image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 5px solid var(--color-white);
}

@media (width >= 1440px) {
  .container {
    width: 450px !important;
    height: 550px !important;
  }
}