.categories__wrapper {
  display: flex;
  width: 100%;
  height: 100%;
}

.categories__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.errorIn{
  border: solid red 2px;
  background-color: rgba(220, 20, 60, 0.021);
}

.categories__section {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section__top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: var(--color-white);
  border-radius: 5px;
}

.section__title {
  color: var(--color-black);
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.section__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: var(--color-white);
  border-radius: 5px;
}

.top__input {
  width: 492px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: var(--color-black);
  border-radius: 5px;
  border: 1px solid #B9B9B9;
  cursor: default;
}

.top__input::placeholder {
  color: #B9B9B9;
}

.list__title {
  color: #767676;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

.categories__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}