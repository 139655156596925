.map__wrapper {
  width: 100%;
  height: 760px;
  position: relative;
  margin-bottom: 150px;
}

.map__container {
  width: 100%;
  height: 100%;
  background-color: antiquewhite;
}

.balloon__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.balloon__title {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  color: var(--color-black);
}

.balloon__text {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

@media(width >= 768px) {
  .map__wrapper {
    margin-bottom: 76px;
  }
}

@media (width >= 1440px) {
  .map__wrapper {
    height: 590px;
    margin-bottom: 106px;
  }
}