.smoke {
  position: absolute;
  top: -170px;
  left: -5px;
}

@media (width >= 768px) {
  .smoke {
    top: -600px;
  }
}

@media (width >= 1440px) {
  .smoke {
    top: -1215px;
  }
}