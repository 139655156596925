.viewer__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewer__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.viewer__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.viewer__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.viewer__empty {
  width: 100%;
  background-color: white;
  color: var(--color-black);
  padding: 60px 20px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.empty__text {
  text-align: center;
}

.repeat__button {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 30px;
  border: 1px solid var(--color-primary-btn);
  border-radius: 5px;
  color: var(--color-black);
  outline: none;
  background: transparent;
}

.repeat__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  white-space: nowrap;
}

.header__text {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
}

.header__right {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  background: none;
  color: white;
}

.right__text {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.right__icon {
  width: 24px;
  height: 24px;
}

.right__icon path {
  stroke: var(--color-white);
}

.cart__list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cart__total {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background: var(--color-white);
  border-radius: 5px;
  padding: 30px;
}

.total__top {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.top__header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  color: var(--color-black);
}

.header__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
}

.header__sum {
  font-weight: 400;
  font-size: 20px;
  line-height: 33px;
}

.top__footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #7A7A7A;
  gap: 20px;
}

.footer__title,
.footer__sum {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.total__divider {
  width: 100%;
  height: 1px;
  background: #D9D9D9;
}

.total__bottom {
  width: 100%;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.guest__button {
  width: 100%;
  height: 50px;
  border: 1px solid #F08200;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-black);
  background: transparent;
}

.guest__button:hover {
  background: var(--color-primary-btn);
  color: var(--color-white);
}

.cleanup__popup {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 30px;
  background: var(--color-white);
  color: var(--color-black);
  border-radius: 10px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #a7a7a7;
}

@media (width >=768px) {
  .viewer__container {
    gap: 20px;
  }

  .viewer__content {
    gap: 30px;
  }
}

@media (width >=1440px) {
  .viewer__content {
    flex-direction: row;
    gap: 24px;
  }

  .viewer__empty,
  .cart__list,
  .viewer__header {
    width: 744px;
  }

  .cart__total {
    width: 360px;
  }
}