.item__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 20px;
}

.icon__custom {
  stroke: var(--color-black);
}

.icon__rotate {
  transform: rotate(45deg);
}