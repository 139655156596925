.select__wrapper {
  width: 100%;
  padding: 10px;
  background-color: var(--color-white);
  border-radius: 5px;
  position: relative;
}

.select__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.select__value {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.value__text,
.select__option {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.select__caret {
  width: 24px;
  height: 24px;
  background: url('../../../assets/icons/select_caret.png');
  cursor: pointer;
}

.select__options {
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  background: var(--color-white);
  border-radius: 5px;
  border: 1px solid #a7a7a7;
  position: absolute;
  z-index: 5;
  top: 40px;
  right: 0;
}

.select__option {
  width: 100%;
  padding-left: 5px;
  cursor: pointer;
}

.select__option:hover {
  background: rgba(183, 183, 183, 0.5);
  border-radius: 5px;
}

@media (width >=768px) {

  .select__wrapper,
  .select__options {
    width: 344px;
  }

  .value__text,
  .select__option {
    font-size: 20px;
    line-height: 27px;
  }
}

@media (width >=1440px) {

  .select__wrapper,
  .select__options {
    width: 360px;
  }
}