.orders__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.orders__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.orders__widget {
  width: 100%;
  flex-direction: column;
  display: flex;
  color: var(--color-black);
  gap: 20px;
}

.widget__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.list__wrapper {
  /* height: 100vh; */
  overflow-y: auto;
}

.orders__widget:last-child {
  height: 100%;
  overflow: hidden;
}