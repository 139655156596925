.statistics__row {
  width: 100%;
  display: flex;
  gap: 24px;
}

.row__item {
  width: 100%;
  height: 154px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--color-white);
  color: var(--color-black);
}

.item__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.item__title {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.item__value {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
}