.step__wrapper {
  width: 100%;
  height: 275px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.step__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
}

.step__content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
}

.content__left {
  flex-basis: 55%;
  display: flex;
  justify-content: center;
}

.content__right {
  flex-basis: 45%;
}

@media (width >= 768px) {
  .step__wrapper {
    height: 550px;
  }
}

@media (width >= 1440px) {
  .step__wrapper {
    height: 1330px;
  }

  .content__left {
    flex-basis: 70%;
  }
}

@media (width >= 1920px) {
  .step__wrapper {
    padding: 0px 250px;
  }

  .step__container {
    padding-left: 150px;
  }

  .content__left {
    flex-basis: 55%;
  }
}