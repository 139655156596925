.curtain__wrapper {
  width: 258px;
  height: 100vh;
  background-color: #222222;
  box-shadow: 
    0px 0px 0px 0px rgba(0, 0, 0, 0.01), 
    -16px 14px 46px 0px rgba(0, 0, 0, 0.01),
    -65px 54px 84px 0px rgba(0, 0, 0, 0.01), 
    -145px 123px 114px 0px rgba(0, 0, 0, 0.01), 
    -258px 218px 135px 0px rgba(0, 0, 0, 0.00), 
    -403px 340px 148px 0px rgba(0, 0, 0, 0.00);
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  padding: 36px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 10;
}

.curtain__content {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.content__top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-top: 44px;
}

.top__circle {
  width: 98px;
  height: 98px;
  border-radius: 100px;
  border: 1px solid #363636;
  background: #5C5C5C;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top__text {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.icon__custom {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 36px;
  right: 16px;
}

.icon__custom path {
  stroke: var(--color-white);
}

.chief__custom {
  width: 48px;
  height: 48px;
}

.chief__custom path {
  stroke: var(--color-white);
}


.content__bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 60px;
}

.catalog__link {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: var(--color-primary-btn);
  border-radius: 5px;
  gap: 10px;
}

.link__text {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: var(--color-white);
}