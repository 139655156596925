.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  color: var(--color-white);
  border-radius: 5px;
}

.button__container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
}

.button:disabled {
  background: var(--color-disable);
  cursor: not-allowed;
}

.button__primary {
  background: var(--color-primary-btn);
}

.button__primary:not([disabled]):hover {
  background: var(--color-primary-hover);
}

.button__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  gap: 4px;
}

.button__secondary {
  background: var(--color-black);
}

.button__secondary:hover {
  background: var(--color-secondary-hover);
}

.full {
  width: 100%;
  justify-content: center;
}

@media (width >=768) {
  .button {
    padding: 12px 30px;
  }
}