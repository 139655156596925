@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

.logo__wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
}

.logo__text {
  color: var(--color-black);
  text-align: center;
  font-family: 'Roboto Slab, sans-serif';
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  white-space: nowrap;
}