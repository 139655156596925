.button__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 12px;
  flex-wrap: nowrap;
  justify-content: space-between;
  background: var(--color-black);
  border-radius: 5px;
  z-index: 25;
  position: relative;
  cursor: pointer;
}

.button__icon {
  width: 24px;
  height: 24px;
}

.button__icon path {
  stroke: var(--color-white);
}

.button__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-white);
}

.button__text span:last-child {
  margin-left: 4px;
}