.page__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.page__container {
  width: 100%; 
  height: 100%;
  padding-top: 122px;
  display: flex;
  justify-content: center;
  height: 500px;
}

.page__content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  position: relative;
  z-index: 2;
}

.content__top {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.top__img {
  width: 186px;
  height: 93px;
}

.top__text {
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  text-align: center;
  color: var(--color-white);
}

.not_found__text {
  width: 160px;
}

.content__button {
  width: 100%;
}

@media (width >= 768px) {
  .page__container {
    padding-top: 194px;
    height: 635px;
  }

  .top__img {
    width: 374px;
    height: 187px;
  }

  .top__text {
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;
  }

  .not_found__text {
    width: 528px;
  }

  .content__button {
    width: 300px;
  }
}

@media (width >= 1440px) {
  .page__container {
    padding-top: 176px;
    height: 726px;
  }

  .top__img {
    width: 500px;
    height: 250px;
  }
}

@media (width >= 1920px) {
  .page__container {
    height: 780px;
  }
}

