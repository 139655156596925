.catalog_item__wrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.catalog_item__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--color-white);
  gap: 40px;
}

.item__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header__left {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header__right {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-end;
  justify-content: center;
}

.header__button,
.footer__button {
  width: 141px;
}

.header__top {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  white-space: nowrap;
}

.header__bottom {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  white-space: nowrap;
}

.item__img {
  width: 165px;
  height: 254px;
  border-radius: 5px;
  border: 5px solid var(--color-white);
  transform: rotate(7deg) translateX(16px);
}

.icon__custom {
  stroke: var(--color-white);
  fill: var(--color-white);
}

.item__footer {
  width: 100%;
  height: 50px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.item__link {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.button__icon {
  width: 24px;
  height: 24px;
  stroke: var(--color-white);
}

@media (width >= 768px) {
  .header__top {
    font-size: 48px;
    font-weight: 700;
    line-height: 65px;
    white-space: normal;
  }

  .header__bottom {
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
    white-space: normal;
  }
}

@media (width >= 1440px) {
  .header__right,
  .header__bottom,
  .item__img {
    display: none;
  }

  .hovered__block {
    display: block;
  }

  .hovered__flex {
    display: flex;
  }

  .header__right.hovered__flex {
    justify-content: flex-end;
  }

  .header__left {
    width: 480px;
  }

  .item__img {
    position: absolute;
    width: 349px;
    height: 537px;
    right: 20%;
    top: -47.5%;
  }

  .item__link {
    align-items: flex-end;
    flex-basis: 10%;
  }

  .footer__button {
    width: 184px;
  }
}

/* .catalog_item__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  position: relative;
}

.item__header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: var(--color-white);
  cursor: default;
}

.opened {
  width: 1127px;
  justify-content: space-between;
}

.header__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.left__title {
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
}

.left__subtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}

.header__right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.right__link {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.icon {
  width: 20px;
  height: 20px;
}

.icon path {
  stroke: var(--color-white);
}

.item__arrow {
  width: 482px;
  height: 22px;
}

.long {
  width: 1130px;
}

.arrow {
  width: 100%;
  height: 100%;
}

.arrow circle,
.arrow path {
  fill: var(--color-shadow-grey);
}

.arrow.long circle,
.arrow.long path {
  fill: var(--color-white);
}

.item__image {
  width: 349px;
  height: 537px;
  border-radius: 5px;
  transform: rotate(7deg);
  position: absolute;
  top: -150px;
  right: 165px;
  display: none;
  z-index: 1;
}

.visible {
  display: block;
  transition: all 1s linear;
}

@media (width < 769px) {
  .wrapper__tablet {
    height: 500px;
    position: relative;
  }

  .item__header {
    width: 704px;
    align-items: flex-start;
  }

  .header__left {
    max-width: 435px;
  }

  .header__right {
    padding-top: 25px;
  }

  .item__image {
    width: 166px;
    height: 255px;
    top: 50%;
    left: 20px;
  }
} */



