.order_item__wrapper {
  display: flex;
  padding: 10px;
  align-items: center;
  border-radius: 5px;
  color: var(--color-black);
}

.order_item__wrapper:hover {
  background-color: #E0E1FF;
}

.item__elem {
  width: 20%;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 3px;
}

.element__left {
  width: 84%;
  display: flex;
}

.status__column {
  width: 16%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}