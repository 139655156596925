.dragmodal__wrapper {
  width: 630px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-color: var(--color-white);
  position: relative;
  z-index: 100;
}

.dragmodal__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.modal__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
}

.icon__custom {
  stroke: var(--color-black);
  cursor: pointer;
}

.icon__custom:hover {
  stroke: #9a9a9a;
}