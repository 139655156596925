.order_details__wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, '154px');
  gap: 12px 24px;
}

.order_details__element {
  display: flex;
  width: 100%;
  height: 154px;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
  background-color: var(--color-white);
  border-radius: 10px;
}
.item__input {
  width: 310px;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--color-white);
  border: 1px solid #e8e8e8ce;
  color: black;
}
.order_details__element:last-child {
  position: relative;
}

.element__row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.specific__row {
  display: flex;
  justify-content: flex-end;
}

.row__text {
  color: var(--color-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.row__text_lighten {
  color: #989898;
  margin-right: 3px;
}

.order__status {
  display: flex;
  gap: 5px;
}

.icon__custom {
  width: 24px;
  height: 24px;
}

.icon__custom path {
  cursor: pointer;
  stroke: var(--color-black);
  fill: transparent;
}

.status__list {
  width: 180px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 5px;
  border-radius: 10px;
  background: var(--color-white);
  position: absolute;
  top: 55px;
  right: 25px;
  border: 1px solid #7a7a7a;
}

.list__item {
  width: 100%;
  padding: 5px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.list__item:hover {
  background-color: #989898;
}