.headertop__wrapper {
  width: 100%;
  background-color: var(--color-black);
  color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
}

.headertop__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headertop__element{
  height: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
}

@media (width >= 768px) {
  .headertop__element {
    font-size: 16px;
    line-height: 22px;
  }
}