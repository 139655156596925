.dnd__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

.dnd__droparea {
  width: 100%;
  height: 183px;
  border-radius: 10px;
  border: 1px dashed #C0C0C0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.ready__droparea {
  border: 1px solid #2024F2;
  background: rgba(32, 36, 242, 0.10);
}

.dnd__label {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.icon__custom {
  stroke: #2d2d2d;
  fill: transparent;
}

.dnd__input {
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
}

.dnd__title {
  color: #2D2D2D;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.dnd__subtitle {
  color: #C0C0C0;
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}

.previews__section {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  position: relative;
  gap: 20px;
  padding-top: 10px;
}

.section__title {
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
  color: #9a9a9a;
}

.empty__list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2d2d2d;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.files__list {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  padding: 20px 0px;
}

.list__item {
  width: 100%;
}

.button__container {
  width: 126px;
}