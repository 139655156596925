.dashboard__header {
  width: 100%;
  padding: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
}

.header__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.header__button {
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

}

.header__button button {
  background-color: transparent;
}

.button__text {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #767676;
}

.repeat__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 30px;
  border: 1px solid var(--color-primary-btn);
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-black);
  outline: none;
  background: transparent;
}