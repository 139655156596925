.page__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.page__container {
  width: 100%;
  padding-top: 50px;
  z-index: 2;
}

.page__content {
  margin-top: 30px;
  width: 100%;
  background: var(--color-white);
  color: var(--color-black);
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.content__part {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

@media (width >=768px) {
  .page__container {
    padding-top: 100px;
  }

  .page__content {
    margin-top: 60px;
  }

  .content__part {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (width >=1920px) {
  .page__container {
    max-width: 1128px !important;
  }
}