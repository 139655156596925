.slider__container {
  width: 100%;
  height: 180px;
  display: flex;
  gap: 14px;
}

.slider__item {
  width: 135px !important;
  height: 180px;
}

.video__item {
  width: 212px !important;
  height: 143px;
}

.slider__image {
  width: 135px;
  height: 180px;
  border-radius: 1.875px;
  border: 1.875px solid var(--color-white);
}

.icon__custom {
  width: 28px;
  height: 27px;
}

media-outlet {
  height: 143px;
  border-radius: 2.5px;
}

media-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

media-play-button:not([data-paused]) {
  opacity: 0.1;
}

media-mute-button {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

media-fullscreen-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}