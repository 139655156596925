.file__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper__left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.wrapper__right {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.file__title {
  color: var(--color-black);
  text-align: center;
  font-family: Manrope, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.file__status {
  display: flex;
  gap: 5px;
}

.status__text {
  display: flex;
  align-items: center;
  font-family: Manrope, sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: #34C002;
}

.text__error {
  color: #FF5000;
}

.icon__custom {
  stroke: var(--color-black);
}

.error__icon {
  fill: #FF5000;
}