.map_form__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 70px;
  left: 0;
}

.map_form__container {
  display: flex;
  width: 100%;
  padding: 30px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--color-white);
  border-radius: 10px;
}

.map_form__title {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  line-height: 27px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.map_form__inputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.map_form__input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #B9B9B9;
  padding: 10px;
}

.map_form__input::placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

.map_form__bottom {
  margin-top: 30px;
  width: 100%;
}

.map_form__capture {
  display: flex;
  width: 181px;
  padding: 10px 30px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  border: 1px solid #000;
}

.error__message {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #CC2929;
  opacity: 0;
}

.visible {
  opacity: 1;
}

.form__input:focus {
  border: 1px solid #FCD4BA;
}

.form__input:disabled {
  background: #DFDFDF;
}

.error,
.error:focus {
  border: 1px solid #CC2929;
}

@media (width >= 768px) {
  .map_form__wrapper {
    width: 456px;
    top: 68px;
    left: 50%;
    transform: translateX(-50%);
  }
  .map_form__container {
    width: 456px;
  }

  .map_form__title {
    font-size: 24px;
    line-height: 33px;
  }

  .map_form__bottom {
    width: 148px;
  }
}

@media (width >= 1440px) {
  .map_form__wrapper {
    top: 100px;
    left: 27%;
  }
}