.userinfo__wrapper {
  display: flex;
  align-items: center;
  gap: 60px;
  color: var(--color-black);
}

.userinfo__data {
  display: flex;
  align-items: center;
  gap: 30px;
}

.user,
.guest__section {
  display: flex;
  align-items: center;
  gap: 10px;
  display: none;
  cursor: pointer;
}

.user__name,
.guest__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.cart__info {
  display: flex;
  align-items: center;
  gap: 5px;
}

.cart__text {
  display: flex;
  gap: 2px;
}

.cart__text span {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.icon__custom path {
  stroke: #333333;
}

@media (width >= 768px) {
  .user,
  .guest__section {
    display: flex;
  }
}