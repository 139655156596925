.step__wrapper {
  width: 100%;
  height: 488px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-white);
}

.wrapper_blocked {
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #ffffff;
  overflow: hidden;
}

.step__container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step__content {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.step__title {
  width: 60%;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}

.step__link {
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;
}

.link__text {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

@media (width >=768px) {
  .step__wrapper {
    height: 726px;
  }

  .step__content {
    align-items: center;
    gap: 60px;
  }

  .step__title {
    font-size: 48px;
    line-height: 65px;
    text-align: center;
    width: 85%;
  }

  .step__link {
    flex-direction: column;
    gap: 0;
    align-items: center;
  }
}

@media (width >=1440px) {
  .step__wrapper {
    height: 726px;
  }

  .step__content {
    width: 100%;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  .step__title {
    font-size: 82px;
    line-height: 112px;
    text-align: left;
    max-width: 578px;
  }
}

@media (width >=1920px) {
  .step__wrapper {
    height: 936px;
  }

  .step__content {
    width: 1128px;
    align-items: flex-end;
  }
}