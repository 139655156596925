.item__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #E8E8E8;
  padding: 10px;
}

.item__wrapper:hover {
  border: 1px solid rgba(224, 225, 255, 0.3);
  background-color: rgba(224, 225, 255, 0.3);
  transition: all 0.3s ease;
}

.active,
.active:hover {
  border: 1px solid rgba(224, 225, 255, 1);
  background-color: rgba(224, 225, 255, 1);
}

.item__container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
}

.item__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.content__left {
  flex-basis: 51%;
  display: flex;
  gap: 80px;
  align-items: center;
}

.content__right {
  flex-basis: 35%;
  display: flex;
  justify-content: space-between;
}

.right__elements {
  display: flex;
  gap: 60px;
  align-items: center;
}

.item__element {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: var(--color-black);
  display: flex;
  gap: 3px;
  width: 30px;
}

.content__left>.item__element:first-child {
  width: 305px;
}

.item__icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  opacity: 0;
}

.item__wrapper:hover .item__icons {
  opacity: 0.3;
}

.active .item__icons,
.active:hover .item__icons {
  opacity: 1;
}

.icon__custom {
  width: 16px;
  height: 16px;
  stroke: var(--color-black);
  fill: transparent;
  cursor: pointer;
}

.row {
  display: flex;
  gap: 20px;
}

.removeCartWindow {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap: 30px;
  background: var(--color-white);
  color: var(--color-black);
  border-radius: 10px;
  position: absolute;
  top: 20%;
  left: 33%;
  border: saddlebrown solid 2px;
}

.repeat__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 30px;
  border: 1px solid var(--color-primary-btn);
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-black);
  outline: none;
  background: transparent;
}

.photo {
  width: 40px;
  height: 40px;
}

@media (width >=1920px) {
  .content__left {
    gap: 260px;
  }
}