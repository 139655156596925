.sidebar__wrapper {
  width: 80px;
  height: 100%;
  background-color: var(--color-white);
  padding: 40px 5px;
  display: flex;
}

.sidebar__container {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.sidebar__link {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.sidebar__link:hover {
  color: #89C6FF;
  cursor: pointer;
}

.active {
  color: #0004F0;
}

.active > .link__icon {
  fill: #0004F0;
}

.sidebar__link:hover > .link__icon {
  fill: #89C6FF;
}

.link__icon {
  width: 30px;
  height: 30px;
  fill: var(--color-black);
}

.link__text {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
}