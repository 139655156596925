.item__wrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px;
  border-radius: 5px;
  border: 1px solid #E8E8E8;
}

.active,
.item__wrapper.active:hover {
  background-color: rgba(224, 225, 255, 1);
  border: 1px solid rgba(224, 225, 255, 1);
}

.item__wrapper:hover {
  border: 1px solid rgba(224, 225, 255, 1);
  background-color: rgba(224, 225, 255, 0.5);
  transition: all 0.3s ease;
}

.item__wrapper:hover > .item__input:disabled,
.active > .item__input:disabled {
  background-color: rgba(224, 225, 255, 0.1);
  transition: all 0.4s ease;
}

.item__wrapper:hover > .item__icons {
  opacity: 0.5;
  transition: all 0.4s ease;
}

.active > .item__icons {
  opacity: 1;
  transition: all 0.4s ease;
}

.item__input {
  width: 310px;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--color-white);
  border: 1px solid #E8E8E8;
}

.item__input:disabled {
  border: none;
  background-color: var(--color-white);
}

.item__icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  opacity: 0;
}

.icon__custom {
  width: 16px;
  height: 16px;
  stroke: var(--color-black);
  fill: transparent;
  cursor: pointer;
}


.removeCartWindow{
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 30px;
  gap : 30px;
  background: var(--color-white);
  color: var(--color-black);
  border-radius: 10px;
  position: absolute;
  top: 20%;
  left: 33%;
  border: saddlebrown solid 2px;
}

.repeat__button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 30px;
  border: 1px solid var(--color-primary-btn);
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--color-black);
  outline: none;
  background: transparent;
}

.row{
  display: flex;
  gap: 50px;
}

.button__save{
  top: 5px;
  left: 270px;
position: absolute;
background-color: transparent;
}

.position{
  position: relative;
}