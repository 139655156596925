:root {
  --primary-bg: #2F3437;
  --color-carrot: #F5894F;
  --color-white: #ffffff;
  --color-apricot: #FCD4BA;
  --color-black: #000000;
  --color-bright-yellow: #FAB400;
  --color-shadow-grey: #6C6C6C;
  --color-primary-btn: #F08200;
  --color-primary-hover: #F5894F;
  --color-secondary-hover: #2E2E2E;
  --color-disable: #9A9A9A;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

body {
  overflow-x: hidden;
}

ul {
  list-style: none;
}

input,
button,
textarea,
select {
  border: none;
  outline: none;
  cursor: pointer;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

a,
a:visited,
a:hover {
  text-decoration: none;
  color: inherit;
}

:focus,
:active {
  outline: none;
}

h1::first-letter,
h2::first-letter,
h3::first-letter,
h4::first-letter,
h5::first-letter,
h6::first-letter {
  text-transform: capitalize;
}

.container {
  max-width: calc(100% - 32px);
}

.page__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.page__shadow {
  width: 100%;
  height: 120%;
  background-color: var(--primary-bg);
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.page__title {
  color: var(--color-white);
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
}

.icon__default {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.step__title {
  color: #FAB400;
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
}

.os-scrollbar .os-scrollbar-track {
  background: transparent;
  margin-left: 5px;
}

.os-scrollbar .os-scrollbar-thumb {
  width: 5px;
}

.os-scrollbar-handle {
  background: #ffffff;
  border-radius: 15px;
}

[class*="balloon__layout"] {
  border-radius: 5px !important;
  padding: 10px 20px !important;
}

[class*="balloon__content"] {
  padding: 0 !important;
}

[class*="balloon__tail"] {
  display: none !important;
}

@media (width >=768px) {
  .container {
    max-width: 704px;
  }

  .page__title {
    font-size: 64px;
    font-weight: 800;
    line-height: 87px;
  }

  .step__title {
    font-size: 48px;
    line-height: 65px;
  }
}

@media (width >=1440px) {
  .container {
    max-width: 1128px;
  }

  .step__title {
    font-size: 64px;
    font-weight: 800;
    line-height: 87px;
  }
}

@media (width >=1920px) {
  .container {
    max-width: 1608px;
  }
}