.good__wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}

.good__container {
  width: 100%;
  height: 100%;
  padding-top: 53px;
  z-index: 2;
}

.good__title {
  font-size: 32px;
  font-weight: 700;
  line-height: 44px;
  color: var(--color-white);
  margin-top: 14px;
}

.good__detail {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 30px;
}

.detail__images {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.detail__image_big {
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  height: 288px;
  position: relative;
}

.image__pic {
  width: 100%;
  height: 100%;
}

.image__handler {
  width: 29px;
  height: 29px;
  background: var(--color-shadow-grey);
  border-radius: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;
}

.image__handler:hover {
  background: var(--color-black);
  transition: all 0.2s linear;
}

.handler__left {
  left: 9px;
}

.handler__right {
  right: 9px;
}

.handler__arrow {
  width: 24px;
  height: 24px;
}

.handler__arrow path {
  stroke: var(--color-white);
}

.handler__disabled {
  display: none;
}

.detail__gallery {
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.gallery__item {
  width: 25%;
  height: 100%;
}

.item__image {
  width: 100%;
  height: 100%;
}

.detail__conditions {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background: var(--color-white);
  border-radius: 5px;
}

.conditions__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.conditions__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.content__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  color: var(--color-black);
}

.item__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  white-space: nowrap;
}

.item__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.icon {
  width: 24px;
  height: 24px;
}

.icon path {
  stroke: var(--color-white);
}

.good__description {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  color: var(--color-white);
  margin-bottom: 100px;
}

.description__title {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
}

.description__text {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

@media (width >=768px) {
  .good__title {
    font-size: 48px;
    font-weight: 700;
    line-height: 65px;
  }

  .detail__images {
    margin-top: 0;
  }

  .detail__conditions {
    height: 562px;
  }

  .good__detail {
    flex-direction: row;
    gap: 16px;
    margin-bottom: 70px;
  }

  .good__description {
    margin-top: 40px;
  }

  .detail__image_big {
    height: 344px;
  }

  .image__handler {
    width: 34px;
    height: 34px;
  }
}

@media (width >=1440px) {
  .good__title {
    font-size: 64px;
    font-weight: 800;
    line-height: 87px;
  }

  .good__container {
    padding-top: 100px;
    padding-bottom: 96px;
  }

  .detail__image_big {
    height: 620px;
  }

  .detail__images {
    width: 648px;
  }

  .good__description {
    margin-top: 50px;
  }

  .detail__conditions {
    width: 463px;
    padding: 30px;
    height: 779px;
  }

  .item__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
  }

  .item__text {
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
  }

  .conditions__container {
    height: 100%;
    justify-content: space-between;
  }

  .description__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 33px;
  }

  .description__text {
    font-size: 20px;
    font-weight: 400;
    line-height: 27px;
  }

  .handler__left {
    left: 26px;
  }

  .handler__right {
    right: 26px;
  }
}

@media (width >=1920px) {
  .good__container {
    width: 1128px !important;
  }
}