.switch__label {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 16px;
}

.switch__input {
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
}

.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 3.4em;
  z-index: 5;
}

.switch__slider::before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: 2px;
  background-color: var(--color-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.switch__input:checked+.switch__slider {
  background-color: #6750A4;
}

.switch__input:checked+.switch__slider::before {
  transform: translateX(9px);
}