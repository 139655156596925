.search {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-8px);
}

.search__box {
  width: 100%;
  position: relative;
}

.search__input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #ACACAC;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  position: relative;
  z-index: 5;
}

.search__input::placeholder {
  color: var(--color-black);
}

.icon__custom {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  z-index: 5;
}

.icon__custom path {
  stroke: var(--color-black);
}

.icon__custom:hover path {
  stroke: #a7a7a7;
}

.search__nothing,
.search__list {
  width: 100%;
  position: absolute;
  top: 40px;
  left: 0;
  background-color: var(--color-white);
  border: 1px solid #ACACAC;
  border-radius: 5px;
  padding: 20px 10px 10px 10px;
  z-index: 4;
  transform: translateY(-10px);
}

.search__list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.search__item {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  display: flex;
  gap: 10px;
  align-items: center;
}

.item__highlight {
  font-weight: 600;
}

.item__img {
  width: 30px;
  height: 30px;
}

@media (width >=768px) {
  .search {
    width: 100%;
    transform: translateY(-4px);
  }

  .search__box {
    max-width: 295px;
    margin: 0 auto;
  }
}

@media (width >=1440px) {
  .search__box {
    max-width: 685px;
  }
}

@media (width >=1920px) {
  .search__box {
    max-width: 1165px;
  }
}