.group__wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.group__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon__custom {
  cursor: pointer;
  position: relative;
  z-index: 8;
}

.icon__custom:hover path,
.icon__custom:hover rect {
  stroke: #a7a7a7;
}