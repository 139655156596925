.page__wrapper {
  width: 100%;
  height: 100%;
  min-height: 726px;
  display: flex;
  justify-content: center;
  position: relative;
}

.page__shadow {
  width: 100%;
  height: 120%;
  background-color: var(--primary-bg);
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.page__container {
  width: 360px;
  padding-top: 185px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

@media (width < 426px) {
  .page__wrapper {
    height: 100%;
  }

  .page__container {
    width: 100%;
    height: 100vh;
    padding-top: 0;
    align-items: flex-start;
  }
}