.sausage__wrapper {
  width: 100%;
  height: 100%;
}

.sausage__container {
  width: 440px;
  height: 650px;
  position: absolute;
  top: -110px;
  right: 0;
  background-image: url('../../../assets/img1/sausage_highlight.png');
  background-repeat: no-repeat;
  background-position: 50% 45%;
  z-index: 5;
}

.sausage {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50px;
  right: -125px;
  background-image: url('../../../assets/img1/sausage_desk.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.rotate_forward {
  transform: rotate(360deg);
  transition: all 3s ease-out;
}

.rotate_back {
  transform: rotate(-360deg);
  transition: all 3s ease-out;
}

.swipe__arrow {
  width: 100px;
  height: 195px;
  position: absolute;
  left: 105px;
}

.swipe__up {
  top: 170px;
}

.swipe__down {
  bottom: 65px;
}

@media (width >= 1440px) {
  .sausage__container {
    width: 750px;
    height: 935px;
    top: -35px;
    right: 80px;
    background-size: contain;
  }

  .swipe__arrow {
    width: 200px;
    height: 390px;
    left: 300px;
  }

  .swipe__up {
    top: 0;
  }

  .sausage {
    width: 125%;
    background-image: url('../../../assets/img1/sausage_desk.png');
    background-size: cover;
    top: -35px;
    right: -525px;
  }
}

@media (width >= 1920px) {
  .sausage__container {
    right: 340px;
  }
}
